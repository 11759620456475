<template>
  <div class="merchantlist">
    <NavBar :list="navList" name="商铺设置"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.merchants_stall_number" placeholder="输入商铺商铺号">
            <template slot="pre">商铺号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-col :span="11">
            <MyInput v-model="formInline.merchants_stall_size_min" placeholder="输入商铺面积起">
              <template slot="pre">商铺面积起:</template>
            </MyInput>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <MyInput v-model="formInline.merchants_stall_size_max" placeholder="输入商铺面积止">
              <template slot="pre">商铺面积止:</template>
            </MyInput>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.property_type_id" clearable placeholder="选择商铺类型">
            <el-option v-for="item in typeList" :key="item.property_type_id" :label="item.name"
              :value="item.property_type_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton icon title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="60008" title="新增商铺" @click="() => {
            (batchshovisible = true), getShoptype();
          }
            ">
            <template slot="preImage">
              <img src="../../../unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="60009" title="批量新增商铺" @click="dialogVisible = true">
            <template slot="preImage">
              <img src="../../../unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="60010" title="批量商铺号导入" @click="batchVisible = true">
            <template slot="preImage">
              <img src="../../../unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton title="批量设置" :accessId="31143" @click="batchSet">
            <template slot="preImage">
              <img src="../../../unit/img/st.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton title="导出" :accessId="87073" @click="merchantExport">
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table :data="tableData" @selection-change="handleSelectionChange" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column label="序号" type="index"></el-table-column> -->
          <el-table-column label="商铺号" prop="merchants_stall_number"></el-table-column>
          <el-table-column label="商铺类型名称" prop="property_type.name"></el-table-column>
          <el-table-column label="楼栋名称" prop="building_name"></el-table-column>
          <el-table-column label="商铺指导价" prop="merchants_stall_money"></el-table-column>
          <el-table-column label="商铺面积" prop="merchants_stall_size">
            <template slot-scope="scope">
              <span>{{ scope.row.merchants_stall_size }}㎡</span>
            </template>
          </el-table-column>
          <el-table-column label="建筑面积" prop="area_building">
            <template slot-scope="scope">
              <span>{{ scope.row.area_building }}㎡</span>
            </template>
          </el-table-column>
          <el-table-column label="套内面积" prop="area_inside">
            <template slot-scope="scope">
              <span>{{ scope.row.area_inside }}㎡</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="create_at"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <pop tips="编辑" :accessId="79082" popRight @myclick="edit(scope.row)">
                <img class="icon" src="../../../assets/img/icon/edit.png" alt />
              </pop>
              <pop tips="删除" :accessId="16301" @myclick="del(scope.row)">
                <img class="icon" src="../../../assets/img/icon/deleted.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="apiPage">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="批量新增商铺" :visible.sync="dialogVisible" width="880px" @close="handleClose(1)"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="商铺号开头字母" prop="merchants_stall_make">
          <el-col :span="14">
            <el-input v-model="ruleForm.merchants_stall_make" class="public-input" placeholder="请输入商铺号开头字母"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="商铺类型" prop="property_type_id">
          <el-col :span="14">
            <el-select v-model="ruleForm.property_type_id" class="public-input" placeholder="请输入商铺类型名称">
              <el-option v-for="item in typeList" :key="item.property_type_id" :label="item.name"
                :value="item.property_type_id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="新增商铺数" prop="merchants_stall_number">
          <el-col :span="14">
            <el-input v-model.number="ruleForm.merchants_stall_number" class="public-input" type="number"
              placeholder="请输入商铺数"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="商铺面积" prop="merchants_stall_size">
          <el-col :span="14">
            <el-input-number :controls="false" v-model.number="ruleForm.merchants_stall_size" :step="0.01" :min="0.01"
              placeholder="请输入面积" :precision="2" class="public-input-number public-input" />
          </el-col>
        </el-form-item>
        <el-form-item label="建筑面积">
          <el-input-number :controls="false" placeholder="请输入建筑面积" v-model="ruleForm.area_building" :step="0.01"
            :min="0.01" class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <el-form-item label="套内面积">
          <el-input-number :controls="false" placeholder="请输入套内面积" v-model="ruleForm.area_inside" :step="0.01" :min="0.01"
            class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <el-form-item label="指导价">
          <el-col :span="14">
            <el-input v-model.number="ruleForm.merchants_stall_price" class="public-input" type="number"
              placeholder="请输入指导价"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="楼栋名称" prop="building_name">
          <el-autocomplete v-model="ruleForm.building_name" class="public-input" clearable @change="buildchange"
            @select="handleselect($event, ruleForm)" :fetch-suggestions="(query, cb) => querySearch(query, cb, 'name')"
            placeholder="楼栋名称"></el-autocomplete>
        </el-form-item>
        <el-form-item label="所在楼层" prop="building_floor_id">
          <el-select v-model="ruleForm.building_floor_id" class="public-input" placeholder="">
            <el-option v-for="(item, index) in floorlist" :key="index" :value="item.building_floor_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="confirm"></MyButton>
      </span>
    </el-dialog>

    <el-dialog title="设置" :visible.sync="setDialogVisible" width="880px" @close="setHandleClose"
      :close-on-click-modal="false">
      <el-form :model="setRuleForm" :rules="setRules" ref="setRuleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="指导价" prop="merchants_stall_money">
          <el-col :span="14">
            <el-input v-model="setRuleForm.merchants_stall_money" class="public-input" type="number"
              placeholder="请输入指导价"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="商铺面积" prop="merchants_stall_size">
          <el-col :span="14">
            <el-input-number :controls="false" v-model="setRuleForm.merchants_stall_size" :step="0.01" :min="0"
              :precision="2" placeholder="请输入商铺面积" class="public-input-number public-input" />
          </el-col>
        </el-form-item>
        <el-form-item label="建筑面积">
          <el-input-number :controls="false" placeholder="请输入建筑面积" v-model="setRuleForm.area_building" :step="0.01"
            :min="0" class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <el-form-item label="套内面积">
          <el-input-number :controls="false" placeholder="请输入套内面积" v-model="setRuleForm.area_inside" :step="0.01" :min="0"
            class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <el-form-item label="商铺类型" prop="property_type_id">
          <el-select v-model="setRuleForm.property_type_id" clearable placeholder="选择商铺类型" class="public-input">
            <el-option v-for="item in typeList" :key="item.property_type_id" :label="item.name"
              :value="item.property_type_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋名称" prop="building_name">
          <el-autocomplete v-model="setRuleForm.building_name" class="public-input" clearable @change="buildchange"
            @select="handleselect($event, setRuleForm)" :fetch-suggestions="(query, cb) => querySearch(query, cb, 'name')"
            placeholder="楼栋名称"></el-autocomplete>
        </el-form-item>
        <el-form-item label="所在楼层" prop="building_floor_id">
          <el-select v-model="setRuleForm.building_floor_id" class="public-input" placeholder="">
            <el-option v-for="(item, index) in floorlist" :key="index" :value="item.building_floor_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="setDialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="setConfirm"></MyButton>
      </span>
    </el-dialog>

    <el-dialog title="批量商铺号导入" :visible.sync="batchVisible" :close-on-click-modal="false">
      <el-form label-position="right" label-width="100px">
        <el-form-item label="下载模板">
          <MyButton @click="
            $exported('/merchants_stall/download', {}, '商铺导入模板.xlsx')
            ">模板下载</MyButton>
        </el-form-item>
        <el-form-item label="上传模板">
          <el-upload ref="upload" action="" :on-change="batchfilechange" :multiple="false" :auto-upload="false">
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span>注意：批量导入模板中商铺号为必填</span>
      <template #footer>
        <MyButton @click="batchVisible = false">取消</MyButton>
        <MyButton type="primary" @click="uoloadMerchant" :disabled="!upform" left>确认</MyButton>
      </template>
    </el-dialog>

    <!--  -->
    <el-dialog title="新增商铺" :visible.sync="batchshovisible" @close="handleClose(2)" :close-on-click-modal="false">
      <el-form label-position="right" ref="batchform" :model="batchshopform" :rules="rules" label-width="120px">
        <el-form-item label="商铺号" prop="merchants_stall_name">
          <el-input v-model="batchshopform.merchants_stall_name" placeholder="请输入商铺号" class="public-input"></el-input>
        </el-form-item>
        <el-form-item label="商铺类型" prop="property_type_id">
          <el-select class="public-input" v-model="batchshopform.property_type_id" placeholder="请选择商铺类型">
            <el-option v-for="(item, index) in shoplist" :key="index" :value="item.property_type_id" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋名称" prop="building_name">
          <el-autocomplete v-model="batchshopform.building_name" @change="buildchange" class="public-input" clearable
            @select="handleselect($event, batchshopform)"
            :fetch-suggestions="(query, cb) => querySearch(query, cb, 'name')" placeholder="楼栋名称"></el-autocomplete>
        </el-form-item>
        <el-form-item label="所在楼层" prop="building_floor_id">
          <el-select v-model="batchshopform.building_floor_id" class="public-input" placeholder="请选择所在楼层">
            <el-option v-for="(item, index) in floorlist" :key="index" :value="item.building_floor_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="新增商铺数">
          <el-input placeholder="" v-model="batchshopform.merchants_stall_number" class="public-input"></el-input>
        </el-form-item> -->
        <el-form-item label="商铺面积" prop="merchants_stall_size">
          <el-input-number :controls="false" v-model="batchshopform.merchants_stall_size" :step="0.01" :min="0.01"
            :precision="2" class="public-input-number public-input" placeholder="请输入商铺面积" />
        </el-form-item>
        <el-form-item label="建筑面积">
          <el-input-number :controls="false" placeholder="请输入建筑面积" v-model="batchshopform.area_building" :step="0.01"
            :min="0.01" class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <el-form-item label="套内面积">
          <el-input-number :controls="false" placeholder="请输入套内面积" v-model="batchshopform.area_inside" :step="0.01"
            :min="0.01" class="public-input-number public-input">
          </el-input-number>
        </el-form-item>
        <!-- prop="merchants_stall_money" //新增指导价 用 merchants_stall_price  编辑用 merchants_stall_money-->
        <el-form-item label="指导价">
          <el-input placeholder="请输入指导价" v-model="batchshopform.merchants_stall_price" class="public-input" type="number">
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <myButton type="" @click="batchshovisible = false">取消</myButton>
        <myButton left type="primary" :loading="btnloading" @click="batchconfirm">新增</myButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "merchantlist",
  data () {
    return {
      navList: [
        {
          name: "商铺设置",
        },
      ],
      formInline: {
        pageNum: 1,
        pageSize: 10,
        property_type_id: "",
        merchants_stall_number: "",
        merchants_stall_size_min: "",
        merchants_stall_size_max: "",
      },
      total: 0,
      tableData: [],
      typeList: [],
      dialogVisible: false,
      setDialogVisible: false,
      ruleForm: {
        merchants_stall_size: "",
        merchants_stall_make: "",
        merchants_stall_number: "",
        property_type_id: "",
        merchants_stall_money: "",
      },
      rules: {
        merchants_stall_name: [
          { required: true, message: "请输入商铺号", trigger: "blur" },
        ],
        merchants_stall_size: [
          { required: true, message: "请输入商铺面积", trigger: "blur" },
        ],
        merchants_stall_make: [
          { required: true, message: "请输入商铺号开头字母", trigger: "blur" },
        ],
        merchants_stall_number: [
          { required: true, message: "请输入商铺数量", trigger: "blur" },
        ],
        property_type_id: [
          { required: true, message: "请选择商铺类型", trigger: "change" },
        ],
        // merchants_stall_price: [
        //   { required: true, message: "请输入指导价", trigger: "blur" },
        // ],
        building_name: [
          { required: true, message: "请选择楼栋", trigger: "change" },
        ],
        building_floor_id: [
          { required: true, message: "请选择所在楼层", trigger: "change" },
        ],
      },
      setRuleForm: {
        merchants_stall_size: "",
        merchants_stall_money: "",
        merchants_stall_ids: [],
        property_type_id: "",
        building_name: "",
        building_id: "",
        building_floor_id: "",
        area_inside: '',
        area_building: ''
      },
      setRules: {
        merchants_stall_size: [
          { required: true, message: "请输入商铺面积", trigger: "blur" },
        ],
        property_type_id: [
          {
            required: true,
            message: "请选择商铺类型",
            trigger: "change",
          },
        ],
        building_name: [
          {
            required: true,
            message: "请选择楼栋",
            trigger: "change",
          },
        ],
        building_floor_id: [
          {
            required: true,
            message: "请选择所在楼层",
            trigger: "change",
          },
        ],
      },
      ids: [],
      batchVisible: false,
      upform: null,
      batchshovisible: false,
      batchshopform: {
        merchants_stall_name: "",
        merchants_stall_number: 1,
        area_building: '',
        area_building: ''
      },
      shoplist: [],
      btnloading: false,
      floorlist: [],
    };
  },
  created () {
    //do something after creating vue instance
    this.getType();
    this.getList();
  },
  methods: {
    merchantExport () {
      this.$request
        .HttpPost("/merchants_stall/export", this.formInline, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "商铺信息导出.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    handleSelectionChange (arr) {
      this.ids = arr;
      console.log(this.ids);
    },
    getList () {
      this.tableData = [];
      this.$request
        .HttpGet("/merchants_stall/list", this.formInline)
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        });
    },
    getType () {
      this.$request
        .HttpGet("/type/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          this.typeList = res.data.list;
        });
    },
    del (item) {
      this.$myconfirm("此操作将永久删除该商铺, 是否继续?", "提示").then(() => {
        this.$request
          .HttpPost("/merchants_stall/delete", {
            ids: [item.merchants_stall_id],
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
    batchSet () {
      if (this.ids.length <= 0) {
        this.$common.notifyError("请选择商铺");
        return;
      }
      this.ids.forEach((item) => {
        this.setRuleForm.merchants_stall_ids.push(item.merchants_stall_id);
      });
      this.setDialogVisible = true;
    },
    edit (item) {
      for (let i in this.setRuleForm) {
        this.setRuleForm[i] = item[i];
      }
      this.setRuleForm.merchants_stall_ids = [item.merchants_stall_id];
      this.getfloors(item.building_id);
      this.setDialogVisible = true;
    },
    //merchants_stall_ids 多选
    setConfirm () {
      this.$refs.setRuleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/merchants_stall/saveAll", this.setRuleForm)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.setDialogVisible = false;
              this.getList();
              this.setRuleForm.merchants_stall_ids = [];
            });
        }
      });
    },
    confirm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/merchants_stall/add", this.ruleForm)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.dialogVisible = false;
              this.getList();
            });
        }
      });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    handleClose (type) {
      if (type == 1) {
        this.ruleForm = {
          merchants_stall_size: "",
          merchants_stall_make: "",
          merchants_stall_number: "",
          property_type_id: "",
          building_name: "",
          building_id: "",
          building_floor_id: "",
        };
        this.$refs.ruleForm.resetFields();
        return false;
      }

      this.batchshopform = {
        merchants_stall_size: "",
        merchants_stall_name: "",
        merchants_stall_number: 1,
        property_type_id: "",
        building_name: "",
        building_id: "",
        building_floor_id: "",
      };
      this.floorlist = [];
      this.$refs.batchform.resetFields();
    },
    setHandleClose () {
      this.setRuleForm.merchants_stall_size = "";
      this.setRuleForm.merchants_stall_money = "";
      this.setRuleForm.building_name = "";
      this.setRuleForm.building_id = "";
      this.setRuleForm.building_floor_id = "";
      this.$refs.setRuleForm?.clearValidate();
    },
    batchfilechange (file) {
      this.upform = new FormData();
      this.upform.append("file", file.raw);
    },
    uoloadMerchant () {
      this.$request
        .HttpPost("/merchants_stall/importTemplate", this.upform)
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.upform = null;
          this.$refs.upload.clearFiles();
          this.batchVisible = false;
          this.getList();
        });
    },
    getShoptype () {
      this.$request.HttpGet("/property/type/list", {
        pageNum: 1,
        pageSize: 99999
      }).then((res) => {
        if (res.data) {
          this.shoplist = res.data.list;
        }
      });
    },
    batchable () { },
    batchconfirm () {
      this.$refs.batchform.validate((valid) => {
        if (valid) {
          this.btnloading = true;
          this.$request
            .HttpPost("/merchants_stall/add", this.batchshopform)
            .then((res) => {
              this.batchshovisible = false;
              this.batchshopform = {};
              this.batchshopform.merchants_stall_number = 1;
              this.getList();
            })
            .finally(() => {
              this.btnloading = false;
            });
        }
      });
    },
    handleselect (item, data) {
      data.building_id = item.building_id;
      this.getfloors(item.building_id);
    },
    buildchange (e) {
      this.setRuleForm.building_floor_id = null;
      this.batchshopform.building_floor_id = null;
      this.ruleForm.building_floor_id = null;
      this.floorlist = [];
    },
    getfloors (id) {
      this.$request.HttpGet("/floor/list", { building_id: id }).then((res) => {
        if (res.data) {
          this.floorlist = res.data.list;
        }
      });
    },
    async querySearch (query, cb, type) {
      let { data } = await this.$request.HttpPost("/building/list", {
        name: query,
      });
      if (data && data.list) {
        data.list.forEach((item) => {
          item.value = item[type];
        });
        return cb(data.list);
      }
      cb([]);
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  text-align: center;
}

.public-input-number {
  :deep(.el-input__inner) {
    text-align: left;
  }
}
</style>
